<script>



import AgentAutoComplete from '@/components/agent-auto-complete-input'
import {
    required,
} from "vuelidate/lib/validators";

import { initAgentApi } from '@/api/agent'
import { getArApApi } from '@/api/cash'
import {financial} from '@/api/misc'

export default {

    emits: ['confirm', 'cancel'],

  
    components: {
       
        AgentAutoComplete
    },

    validations: {
       
        agent_id : {required},
        amount: { required },
        
    },



    data() {

        return {
            submitted: false,
            dpconfig: {
                wrap: true,
                altInput: true,
                dateFormat: "Y-m",
                altFormat: "Y-m",
                ariaDateFormat: "Y-m",
            },

            agent_id : '',
            amount   : '',
            agent_choice: {},
            agents: [],
            agents_choice_list: [],
            list : [],
        }

    },
    methods: {

        query_agent_hold_commission() {
            this.list =  []
            initAgentApi().query_hold_commission({agent_id : this.agent_id}).then(res => {
                if (res.errCode == 0) {
                    
                    res.data.list.map(e => {
                        this.list.push({
                            id        : e.id,
                            due_date  : e.due_date,
                            hold_date : e.hold_date,
                            amount    : e.amount,
                            ref_sid   : e.ref_sid,
                            ref_id    : e.ref_id,
                            hold_type : e.hold_type,
                        })
                    })
                   
                }
            })
        },

        formSubmit() {
           
            this.$v.$touch();
            if (this.$v.$invalid == false) {
                let data = {
                   agent_id     : this.agent_id,
                   hold_records : this.list.filter(e =>e.checked == true)
                }
                getArApApi().pay_agent_commission(data).then(res => {
                    if (res.errCode == 0) {
                      this.$emit('confirm')  
                    } else {
                        this.$alertify.error("Setup Payment Failed ret "+ res.errCode);
                    }
                })
            }
        },

        onSelectedAgent(agent) {
            this.agent = agent.agent
            this.agent_id = agent.agent.id
            this.query_agent_hold_commission()
        },


        onSelectAll(evt) {
            this.list.map(e => e.checked = evt.target.checked)
            this.amount = financial(this.list.reduce((a, c) => a + (c.checked ? c.amount : 0), 0), 2)
        },

        onCheckItem(evt, item) {
            item.checked = evt.target.checked
            this.amount = financial(this.list.reduce((a, c) => a + (c.checked ? c.amount : 0), 0), 2)
        }

    },

    created() {


    },

    mounted() {
        
    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">

                            <label for="choices-agent-input" class="form-label">Agent</label>
                            <AgentAutoComplete @onSelected="onSelectedAgent" :init_data="{style_class :  {'is-invalid': $v.agent_id.$error }}" autocomplete="off" />
                            <div v-if="$v.agent_id.$error" class="invalid-feedback">
                                <span v-if="!$v.agent_id.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="validationCustom01">Amount</label>
                            <input id="validationCustom01" v-model="amount" type="text" class="form-control"
                                placeholder="$" value="Mark" :class="{
                                    'is-invalid': submitted && $v.amount.$error,
                                }"  readonly>
                            <div v-if="submitted && $v.amount.$error" class="invalid-feedback">
                                <span v-if="!$v.amount.required">This value is required.</span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end row-->

                <div class="table-responsive" style="min-height: 50vh;">
                    <div class="table align-middle table-nowrap">
                        <b-table :items="list" :fields="['G','Deal', 'Amount','Type','DueDate']" responsive="sm"   class="table-check">

                            <template #head(G)>
                                <input type="checkbox" @change="onSelectAll($event)"/>
                            </template>

                            <template #cell(G)="data">
                                <input type="checkbox" v-model="data.item.checked" @change="onCheckItem($event, data.item)"/>
                            
                            </template>
                            <template #cell(Deal)="data">
                                {{ data.item.ref_sid }}
                            </template>
                            <template #cell(Amount)="data">
                                ${{ data.item.amount.toLocaleString() }}
                            </template>
                            <template #cell(Type)="data">
                                {{ data.item.hold_type == 0? 'COMMISSION' : 'GST'}}
                            </template>
                            <template #cell(DueDate)="data">
                                {{ data.item.due_date }}
                            </template>
                        </b-table>
                    </div>
                </div>
                 <!-- end table responsive -->
            
                <div class="row">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Submit Payment</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>