<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { getCashApi } from '@/api/cash'


// import flatPickr from "vue-flatpickr-component";
// import "flatpickr/dist/flatpickr.css";
import Choices from "choices.js";

import EftPaymentDialog from './components/eft_payment_dialog.vue'
import PrintChequeDialog from './components/print_cheque_dialog.vue'
import AgentCommissionPaymentDialog from './components/agent_commission_payment_dialog.vue'
import AgentAutoComplete from '@/components/agent-auto-complete-input'


export default {
  page: {
    title: "Account Payable",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "Account Payable",
      items: [
        {
          text: "Agent Commission",
          href: "/",
        },
      ],
      totalRows: 0,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: {AgentCode : ''},
      filterOn: ['type'],
      sortBy: "value",
      sortDesc: false,
      fields: [
        {
          key: "G",
          label : '',
          sortable: true,
        },

        {
          key: "ID",
          label : '',
          sortable: true,
        },

        {
          key: "Payee",
          sortable: true,
        },

        {
          key: "PaymentDate",
          sortable: true,
        },
        {
          key: "Amount",
          sortable: false,
        },

        {
          key: "PayType",
          sortable: true,
        },
        
        {
          key: "Reference",
          sortable: true,
        },
        

        {
          key: "Status",
          sortable: false,
        },

        
      ],
      rangeOptions: {
        mode: "range",
      },
     

      pay_type : '',
      query_type : 'AGENT_COMMISSION',
      query_agent_id: '',
      query_deal_id: '',
      queryRange : '',
      query_status : 'PENDING',
      selected_transactions: [],
      trans_pay_type : '',
      filter_transaction_list: [],
      current_agent : null
    };
  },
  components: {
    Layout,
    PageHeader,
    //flatPickr,
    EftPaymentDialog,
    PrintChequeDialog,
    AgentCommissionPaymentDialog,
    AgentAutoComplete
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },

    cheque_btn_disabled_flag() {
      let progress = this.selected_transactions.filter(e => e.status != 'PENDING')
      return progress.length > 0 ? true : false
     
    },

    eft_btn_disabled_flag() {
      if (this.selected_transactions.length <= 0 || this.trans_pay_type !='EFT') {
        return true
      } else {
        return false
      }
    }
  },

  created() {
    this.queryList()
  },
  mounted() {

    new Choices('#status_choice', {
    });

  //  new Choices('#type_choice')

  },
  methods: {

    queryList() {

      let data = {
        page: {
          page_no: this.currentPage,
          page_size: this.perPage
        },
        query_type : this.query_type,
        pay_type   : this.pay_type,
        status    : this.query_status,
      }

      if (this.current_agent) {
        data.query_agent_id = this.current_agent.id
      }

      getCashApi().ap_list(data).then((res) => {
        this.list = []
        res.data.map((o) => {
          this.list.push(o)
         

        })

        this.totalRows = res.page.total;

      })
    },

    pageChange(p) {
      this.currentPage = p
      this.queryList()
    },

    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
        this.filter_transaction_list = filteredItems
    },


    onShowEFT(evt) {
      let agentCode = this.filter.AgentCode
      if (evt.target.checked == true) {

        this.filter = {PayType : 'EFT', AgentCode:  agentCode}
      } else {
        this.filter = {AgentCode:  agentCode}
      }
    },

    filterTable(row, filter) {
      if (filter.PayType && filter.AgentCode) {
        return row.type == filter.PayType  && row.cash_record.agent.full_name.startsWith(filter.AgentCode)
      } else if (filter.PayType ) {
        return row.type == filter.PayType 
      }  else if (filter.AgentCode) {
        return row.cash_record.agent.full_name.startsWith(filter.AgentCode)
      } else {
        return true
      }
    },


    onSelectAll(evt) {
      if (evt.target.checked == true) {
        this.selected_transactions = this.filter_transaction_list
      } else {
        this.selected_transactions = []
      }

      this.filter_transaction_list.map(t => t.checked = evt.target.checked)
    },


    onSelectedTransaction(evt, t)  {
      if (evt.target.checked == true) {
        let d = this.selected_transactions.find((e)=> e.id == t.id)
        if (!d) {
          this.selected_transactions.push(t)
        }

      } else {
        this.selected_transactions = this.selected_transactions.filter((e)=> e.id != t.id)
      }

      let cheque_cflag  = this.selected_transactions.some(t =>t.type =='CHEQUE')
      let eft_cflag  = this.selected_transactions.some(t =>t.type =='EFT')
      if (cheque_cflag && eft_cflag) {
        this.trans_pay_type = 'MISC'
      } else if (eft_cflag) {
        this.trans_pay_type = 'EFT'
      } else if (cheque_cflag) {
        this.trans_pay_type = 'CHEQUE'
      }
    },

    onEftSetup() {
      this.selected_transactions.map(e => e.status ='PROGRESS')
      this.$bvModal.hide('modal-pm-eft-payment-dialog')
      this.$alertify.message("Setup EFT Success  ");

      this.queryList();
      this.selected_transactions = []
    },

    onPrinted() {
     
      this.selected_transactions.map(t => {
          t.status = 'PROGRESS'       
      })
      
      this.$bvModal.hide('modal-cheque-payment-dialog')
    },

    onSelectedAgent(evt) {
      this.current_agent = evt.agent
      this.queryList()
    },

    onClearAgent() {
      this.current_agent = null
      this.queryList()
    },

    onAgentPaymentSetup() {
      this.$bvModal.hide('modal-agent-commission-payment-dialog')
      this.current_agent = null
      this.currentPage   = 1
      this.queryList()
      this.$alertify.message("Setup Agent Payment Success");
    }


  },

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
 

            <div class="row">


              <div class="col-md-1 mb-3 mt-2">
                <input type="checkbox" class="form-check-control" @change="onShowEFT"/>EFT Only
              </div>


              <div class="mb-2 col-md-2">
                <select name="choices-multiple-remove-button" id="status_choice"  v-model="query_status" @change="queryList()">
                  <option value="PENDING" selected>Pending</option>
                  <option value="PROGRESS">Progress</option>
                  <option value="COMPLETED">Completed</option>
                  <option value="CANCELLED">Cancelled</option>
                </select>

              </div>

              <div class="mb-2 col-md-4">
                <AgentAutoComplete  @onSelected="onSelectedAgent" @onCleared="onClearAgent" autocomplete="off" :init_data="{placeholder : 'Searched by Agent'}" />
              </div>

              <div class="col-md-5 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">

                <b-button variant="info" :disabled="eft_btn_disabled_flag" v-b-modal.modal-pm-eft-payment-dialog>EFT</b-button>
                <b-button variant="info" :disabled="cheque_btn_disabled_flag" v-b-modal.modal-cheque-payment-dialog>Cheque</b-button>
                <b-button variant="info" class="font-size-14" v-b-modal.modal-agent-commission-payment-dialog>+</b-button>
              </div>
              <!-- end col -->

              <b-modal centerd id="modal-pm-eft-payment-dialog" title="EFT" size="lg" hide-footer>
                <EftPaymentDialog :transactions="selected_transactions" :bank_account="'Commission Trust'" @cancel="$bvModal.hide('modal-pm-eft-payment-dialog')" @confirm="onEftSetup()"  />
              </b-modal>
              <b-modal centerd id="modal-agent-commission-payment-dialog" title="Hold Commission Payment" size="lg" hide-footer>
                <AgentCommissionPaymentDialog  @cancel="$bvModal.hide('modal-agent-commission-payment-dialog')" @confirm="onAgentPaymentSetup" />
              </b-modal>

              

              <b-modal centerd id="modal-cheque-payment-dialog" title="Cheque Print" size="lg" hide-footer>
                <PrintChequeDialog :transactions="selected_transactions" :bank_account="'Commission Trust'" @cancel="$bvModal.hide('modal-cheque-payment-dialog')" @confirm="onPrinted"  />
              </b-modal>

              
            </div>

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table :items="list" :fields="fields" responsive="sm" :per-page="perPage" :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"  @filtered="onFiltered"
                  :filter-function="filterTable"
                  class="table-check">

                  <template #head(G)>
                    <input type="checkbox" @change="onSelectAll($event)"/>
                  </template>

                  <template #cell(G)="data">
                    <input type="checkbox" v-model="data.item.checked" @change="onSelectedTransaction($event, data.item)"/>
                   
                  </template>
                  <template #cell(ID)="row">
                    <feather :type="row.detailsShowing? 'chevron-up' :'chevron-down'" @click="row.toggleDetails"></feather>
                  </template>

                  <template #cell(Payee)="data">
                    {{ data.item.payee }}
                  </template>

                  <template #cell(Amount)="data">
                    ${{ data.item.amount.toLocaleString() }}
                  </template>

                  <template #cell(PaymentDate)="data">
                    {{ data.item.due_date }}
                  </template>

                  <template #cell(Reference)="data">
                   
                    <span v-if="data.item.cash_record.ref_type == 'PM_COMM' || !(data.item.cash_record.ref_type) ">
                      {{ 'PM Commission' }}
                    </span>
                    <span v-if="data.item.cash_record.ref_type == 'DEAL' ">
                      <router-link :to="{ name: 'deal-overview', query: { deal_id: `${data.item.cash_record.deal.order_deal_id}` } }"
                        class="text-body fw-medium">{{ data.item.cash_record.deal.order_deal_id }}</router-link>
                    </span>
                    <span v-else>
                      {{ data.item.ref_id }}
                    </span>

                  </template>


                  <template #cell(Status)="data">
                    {{ data.item.status }}
                  </template>
                  <template #cell(PayType)="data">
                    {{ data.item.type }}
                  </template>
                  <template #cell(Remark)="data">
                    {{ data.item.remarks }}
                  </template>

                 


                  <template v-slot:row-details="row">
                    <div class="table align-middle table-nowrap" style="margin-left:30px;">
                      <b-table
                        :items="row.item.details"
                        :fields="[{key : 'Type'},{key : 'SubType'},{key :'Amount'}]"
                        responsive="sm"
                        :per-page="row.item.details.length"

                        class="table-check col-md-10"
                      >
                        <template  #cell(Type)="data">
                          {{ data.item.detail_category }}
                        </template>
                        <template  #cell(SubType)="data">
                          {{ data.item.detail_type }}
                        </template>
                        <template  #cell(Amount)="data">
                          ${{ data.item.detail_amount.toLocaleString() }}
                        </template>

                       
                      </b-table>
                    </div>
                  </template>

                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div class="dataTables_paginate paging_simple_numbers float-end">
                      <!-- pagination -->
                      <b-pagination class="pagination-rounded" v-model="currentPage" :total-rows="totalRows"
                        :per-page="perPage" @change="pageChange"></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
